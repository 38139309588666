.br-table {
  --table-padding: var(--spacing-scale-3x);
  --table-row-size: var(--table-row-medium);
  --table-row-small: var(--spacing-scale-base);
  --table-row-medium: var(--spacing-scale-2x);
  --table-row-large: var(--spacing-scale-3x);
}

.br-table caption {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.br-table .responsive {
  overflow: auto;
}

.br-table tr:hover td.column-collapse {
  background-color: var(--background-alternative);
  background-image: none;
}

.br-table .column-collapse {
  background-color: var(--background-alternative);
  width: 40px;
}

.br-table .column-checkbox {
  width: 24px;
}

.br-table .table-header {
  position: relative;
}

.br-table .table-header.show .top-bar {
  opacity: 0;
  pointer-events: none;
}

.br-table .top-bar {
  align-items: center;
  display: flex;
  padding: var(--spacing-scale-base) var(--spacing-scale-2x) var(--spacing-scale-base) var(--table-padding);
}

.br-table .top-bar .title,
.br-table .top-bar .table-title {
  color: var(--color);
  flex-grow: 1;
  font-size: var(--font-size-scale-up-01);
  font-weight: var(--font-weight-semi-bold);
}

.br-table .top-bar .actions-trigger {
  margin-right: var(--spacing-scale-base);
}

.br-table .top-bar .dropdown [data-toggle="dropdown"] + *,
.br-table .top-bar .dropdown [data-toggle="dropdown-legacy"] + * {
  left: auto;
  right: 0;
}

@media (min-width: 576px) {
  .br-table .top-bar title {
    font-size: var(--font-size-scale-up-03);
    line-height: var(--font-line-height-medium);
  }
}

.br-table .search-bar {
  align-items: center;
  bottom: 0;
  display: none;
  height: 56px;
  left: 0;
  padding-left: var(--table-padding);
  padding-right: var(--spacing-scale-2x);
  position: absolute;
  right: 0;
  top: 0;
}

.br-table .search-bar.show {
  display: flex;
}

.br-table .search-bar .br-input {
  flex: 1;
}

.br-table .search-bar .br-input label {
  display: none;
}

.br-table .search-bar .br-input input {
  margin-bottom: 0;
  margin-top: 0;
}

.br-table .search-bar .br-input input + .br-button {
  --button-size: var(--button-small);
  margin-top: 0;
  position: absolute;
  right: var(--spacing-scale-half);
  top: 0;
}

.br-table .search-bar .br-input + .br-button {
  margin-left: var(--table-row-medium);
}

.br-table .selected-bar,
.br-table .selection-bar {
  --color: var(--color-dark);
  --color-rgb: var(--color-dark-rgb);
  --text-color: var(--color-dark);
  --interactive: var(--interactive-dark);
  --interactive-rgb: var(--interactive-dark-rgb);
  --visited: var(--visited-dark);
  --hover: var(--hover-dark);
  --pressed: var(--pressed-dark);
  --focus-color: var(--focus-color-dark);
  --focus: var(--focus-color-dark);
  --table-padding: var(--spacing-scale-base) var(--spacing-scale-2x);
  align-items: center;
  background-color: var(--interactive-light);
  bottom: 0;
  color: var(--color);
  display: none;
  left: 0;
  padding: var(--table-padding);
  right: 0;
  top: 0;
}

.br-table .selected-bar.show,
.br-table .selection-bar.show {
  display: flex;
}

.br-table .selected-bar .info,
.br-table .selection-bar .info {
  align-items: center;
  display: flex;
  flex-grow: 1;
  white-space: nowrap;
}

.br-table .selected-bar .info [data-toggle="check-all"],
.br-table .selected-bar .info .select-all,
.br-table .selection-bar .info [data-toggle="check-all"],
.br-table .selection-bar .info .select-all {
  font-size: var(--font-size-scale-up-03);
  margin-right: var(--spacing-scale-half);
  padding: 0;
}

.br-table .selected-bar .text,
.br-table .selected-bar .count,
.br-table .selection-bar .text,
.br-table .selection-bar .count {
  line-height: var(--font-line-height-low);
  padding-right: var(--spacing-scale-half);
}

.br-table .selected-bar.is-active,
.br-table .selected-bar.active,
.br-table .selection-bar.is-active,
.br-table .selection-bar.active {
  opacity: 1;
  top: 0;
}

.br-table .footer,
.br-table .table-footer {
  padding: var(--spacing-scale-base) var(--spacing-scale-2x) var(--spacing-scale-base) var(--table-padding);
}

.br-table .footer .br-input .br-button,
.br-table .table-footer .br-input .br-button {
  transform: rotate(180deg);
}

.br-table .footer .br-list,
.br-table .table-footer .br-list {
  bottom: 100%;
}

.br-table.small table,
.br-table.small .headers,
.br-table.small .clone-headers .item {
  --table-row-size: var(--table-row-small);
}

.br-table.medium table,
.br-table.medium .headers,
.br-table.medium .clone-headers .item {
  --table-row-size: var(--table-row-medium);
}

.br-table.large table,
.br-table.large .headers,
.br-table.large .clone-headers .item {
  --table-row-size: var(--table-row-large);
}

.br-table.inverted,
.br-table.dark-mode {
  --color: var(--color-dark);
  --color-rgb: var(--color-dark-rgb);
  --text-color: var(--color-dark);
  --interactive: var(--interactive-dark);
  --interactive-rgb: var(--interactive-dark-rgb);
  --visited: var(--visited-dark);
  --hover: var(--hover-dark);
  --pressed: var(--pressed-dark);
  --focus-color: var(--focus-color-dark);
  --focus: var(--focus-color-dark);
}

.br-table.inverted.inverted,
.br-table.inverted.dark-mode,
.br-table.dark-mode.inverted,
.br-table.dark-mode.dark-mode {
  --color: var(--color-dark);
  --color-rgb: var(--color-dark-rgb);
  --text-color: var(--color-dark);
  --interactive: var(--interactive-dark);
  --interactive-rgb: var(--interactive-dark-rgb);
  --visited: var(--visited-dark);
  --hover: var(--hover-dark);
  --pressed: var(--pressed-dark);
  --focus-color: var(--focus-color-dark);
  --focus: var(--focus-color-dark);
}

.br-table.inverted.inverted.inverted .br-input,
.br-table.inverted.inverted.inverted .br-input label,
.br-table.inverted.inverted.dark-mode .br-input,
.br-table.inverted.inverted.dark-mode .br-input label,
.br-table.inverted.dark-mode.inverted .br-input,
.br-table.inverted.dark-mode.inverted .br-input label,
.br-table.inverted.dark-mode.dark-mode .br-input,
.br-table.inverted.dark-mode.dark-mode .br-input label,
.br-table.dark-mode.inverted.inverted .br-input,
.br-table.dark-mode.inverted.inverted .br-input label,
.br-table.dark-mode.inverted.dark-mode .br-input,
.br-table.dark-mode.inverted.dark-mode .br-input label,
.br-table.dark-mode.dark-mode.inverted .br-input,
.br-table.dark-mode.dark-mode.inverted .br-input label,
.br-table.dark-mode.dark-mode.dark-mode .br-input,
.br-table.dark-mode.dark-mode.dark-mode .br-input label {
  --color: var(--color-dark);
  --focus-color: var(--focus-color-dark);
}

.br-table.inverted.inverted .page.active,
.br-table.inverted.dark-mode .page.active,
.br-table.dark-mode.inverted .page.active,
.br-table.dark-mode.dark-mode .page.active {
  --interactive-rgb: var(--active-rgb);
  background-color: var(--background-light);
  color: var(--active);
}

.br-table.inverted th,
.br-table.inverted td.column-collapse,
.br-table.inverted tr:hover td.column-collapse,
.br-table.dark-mode th,
.br-table.dark-mode td.column-collapse,
.br-table.dark-mode tr:hover td.column-collapse {
  background-color: var(--background-dark);
}

.br-table.no-hover td:not(.column-collapse):hover td {
  background: none;
}

/*# sourceMappingURL=table.css.map*/
